<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">监测机构</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        :class="{ showButton: !saveButton }"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.dataDic"
                :rules="rules"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
            >
                <el-form-item
                    label="监测机构名称"
                    prop="JCJGMC"
                    :style="{ width: '80%' }"
                >
                    <el-input
                        v-model="formdata.dataDic.JCJGMC"
                        :disabled="!isEdit"
                        placeholder="请填写监测机构名称"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item
                    label="监测机构代码"
                    prop="JCJGDM"
                    :style="{ width: '80%' }"
                >
                    <el-input
                        v-model="formdata.dataDic.JCJGDM"
                        :disabled="!isEdit"
                        placeholder="请填写监测机构代码"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item
                    label="负责人"
                    prop="FZR"
                    :style="{ width: '80%' }"
                >
                    <el-input
                        v-model="formdata.dataDic.FZR"
                        :disabled="!isEdit"
                        placeholder="请填写负责人"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item
                    label="负责人联系电话"
                    prop="FZRLXDH"
                    :style="{ width: '80%' }"
                >
                    <el-input
                        v-model="formdata.dataDic.FZRLXDH"
                        :disabled="!isEdit"
                        placeholder="请填写负责人联系电话"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item
                    label="承担的责任"
                    prop=""
                    :style="{ width: '80%' }"
                >
                    <el-input
                        v-model="formdata.dataDic.CDDZR"
                        :disabled="!isEdit"
                        placeholder="请填写承担的责任"
                        type="textarea"
                        :autosize="{ minRows: 1 }"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item
                    label="联系人姓名"
                    prop=""
                    :style="{ width: '80%' }"
                >
                    <el-input
                        v-model="formdata.dataDic.LXRXM"
                        :disabled="!isEdit"
                        placeholder="请填写联系人姓名"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item
                    label="联系电话"
                    prop="LXDH"
                    :style="{ width: '80%' }"
                >
                    <el-input
                        v-model="formdata.dataDic.LXDH"
                        :disabled="!isEdit"
                        placeholder="请填写联系电话"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item
                    label="电子邮箱"
                    prop="DZYX"
                    :style="{ width: '80%' }"
                >
                    <el-input
                        v-model="formdata.dataDic.DZYX"
                        :disabled="!isEdit"
                        placeholder="请填写电子邮箱"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item
                    label="监测工作网站"
                    prop="JCGZWZ"
                    :style="{ width: '80%' }"
                >
                    <el-input
                        v-model="formdata.dataDic.JCGZWZ"
                        :disabled="!isEdit"
                        placeholder="请填写监测工作网站"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item
                    label="上级管理单位"
                    prop="SJGLDW"
                    :style="{ width: '80%' }"
                >
                    <el-input
                        v-model="formdata.dataDic.SJGLDW"
                        :disabled="!isEdit"
                        placeholder="请填写上级管理单位"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item
                    label="机构成立时间"
                    prop="JGCLRQ"
                    :style="{ width: '80%' }"
                >
                    <el-date-picker
                        v-model="formdata.dataDic.JGCLRQ"
                        type="month"
                        :disabled="!isEdit"
                        value-format="yyyy-MM"
                        placeholder="请选择机构成立时间"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item
                    label="人员总数"
                    prop="RYZS"
                    :style="{ width: '80%' }"
                >
                    <el-input
                        v-model="formdata.dataDic.RYZS"
                        :disabled="!isEdit"
                        placeholder="请填写人员总数"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="机构撤销时间" :style="{ width: '80%' }">
                    <el-date-picker
                        v-model="formdata.dataDic.JGCXRQ"
                        type="month"
                        :disabled="!isEdit"
                        value-format="yyyy-MM"
                        placeholder="请选择机构撤销时间"
                    >
                    </el-date-picker>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import infoMixin from "../0_com_js/info_mixin.js";

export default {
    mixins: [infoMixin],
    data() {
        return {
            isEdit: true,
            saveButton: true,
            formdata: {
                dataDic: {
                    JCJGMC: null,
                    JCJGDM: null,
                    FZR: null,
                    FZRLXDH: null,
                    CDDZR: null,
                    LXRXM: null,
                    LXDH: null,
                    DZYX: null,
                    JCGZWZ: null,
                    SJGLDW: null,
                    JGCLRQ: null,
                    RYZS: null,
                    JGCXRQ: null,
                },
                fileids: "",
                itemCode: "20102",
                metaJson: [],
            },
            rules: {
                JCJGMC: [
                    {
                        required: true,
                        message: "请填写保护监测机构名称",
                        trigger: "blur",
                    },
                ],
                JCJGDM: [
                    {
                        required: true,
                        message: "请填写监测机构代码",
                        trigger: "blur",
                    },
                ],
                FZR: [
                    {
                        required: true,
                        message: "请填写负责人",
                        trigger: "blur",
                    },
                ],
                JGCLRQ: [
                    {
                        required: true,
                        message: "请选择机构成立日期",
                        trigger: "blur",
                    },
                ],
                LXDH: [
                    {
                        required: false,
                        message: "此项必须填数字",
                        trigger: "blur",
                        validator: (rule, value) =>
                            parseInt(value, 10) === Number(value),
                    },
                ],
            },
        };
    },
    methods: {
        toNumber: function(value, prop) {
            this.formdata.dataDic[prop] = parseInt(value, 10) || "0";
        },
    },
};
</script>

<style></style>
